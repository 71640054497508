<template>
  <div class="container">
    <div class="top">
      <img src="../assets/img/contact_title_e.png" class="logo" />

      <ul class="top-list">
        <li v-for="(sector, i) in emp.list" :key="i">
          <a :href="'#group_'+(i+1)">{{ sector.deptname }}</a>
        </li>
        <li  v-for="(sector, i) in emp.listSubLast" :key="i">
          <a :href="'#subgroup_'+(i+1)">{{ sector.deptname }}</a>
        </li>
      </ul>
    </div>

    <div class="contact_wrapper">
      <div class="contact_list_bg">
        <div class="contact_list">
          <div class="contact" v-for="(sector, i) in emp.list" :key="i">
            <div class="box">
              <div class="group_a" :id="'group_'+(i+1)">{{ sector.deptname }}</div>
              <div class="group_list">
                <!-- 부문 -->
                <div
                  class="list"
                  v-for="(sectorEmpList, i) in sector.empList"
                  :key="i"
                >
                  <!-- 부문에만 소속 리스트-->
                  <div
                    class="name"
                    @mouseover="sectorEmpList.isShowImg = i"
                    @mouseleave="sectorEmpList.isShowImg = 999"
                  >
                    {{ sectorEmpList.name }}
                    <div
                      v-if="
                        sectorEmpList.isShowImg == i &&
                        sectorEmpList.photoimg != ''
                      "
                      class="img-over"
                    >
                      <img class="profile-img" :src="sectorEmpList.photoimg" />
                    </div>
                  </div>
                  <div class="phone_number">{{ sectorEmpList.phone }}</div>
                  <div class="email">
                    <a :href="'mailto:' + sectorEmpList.email">{{
                      sectorEmpList.email
                    }}</a>
                  </div>
                </div>
              </div>
              <div v-if="sector.has_children == 'Y'">
                <!-- 부문하위 -->
                <div v-for="(office, i) in sector.children" :key="i">
                  <!-- 본부 or 부문-팀 -->
                  <div class="group_b" v-if="office.member_cnt > 0">
                    {{ office.deptname }}
                  </div>
                  <div class="group_list">
                    <div
                      class="list"
                      v-for="(officeEmpList, i) in office.empList"
                      :key="i"
                    >
                      <!-- 부문-본부에만 소속 리스트-->
                      <div
                        class="name"
                        @mouseover="officeEmpList.isShowImg = i"
                        @mouseleave="officeEmpList.isShowImg = 999"
                      >
                        {{ officeEmpList.name }}
                        <div
                          v-if="
                            officeEmpList.isShowImg == i &&
                            officeEmpList.photoimg != ''
                          "
                          class="img-over"
                        >
                          <img
                            class="profile-img"
                            :src="officeEmpList.photoimg"
                          />
                        </div>
                      </div>
                      <div class="phone_number">{{ officeEmpList.phone }}</div>
                      <div class="email">
                        <a :href="'mailto:' + officeEmpList.email">{{
                          officeEmpList.email
                        }}</a>
                      </div>
                    </div>
                    <div v-if="office.has_children == 'Y'">
                      <!-- 본부-팀 -->
                      <div v-for="(team, i) in office.children" :key="i">
                        <div class="team" v-if="team.has_member == 'Y'">
                          {{ team.deptname }}
                        </div>
                        <div
                          class="list"
                          v-for="(teamEmpList, i) in team.empList"
                          :key="i"
                        >
                          <!-- 팀소속 리스트-->
                          <div
                            class="name"
                            @mouseover="teamEmpList.isShowImg = i"
                            @mouseleave="teamEmpList.isShowImg = 999"
                          >
                            {{ teamEmpList.name }}
                            <div
                              v-if="
                                teamEmpList.isShowImg == i &&
                                teamEmpList.photoimg != ''
                              "
                              class="img-over"
                            >
                              <img
                                class="profile-img"
                                :src="teamEmpList.photoimg"
                              />
                            </div>
                          </div>
                          <div class="phone_number">{{ teamEmpList.phone }}</div>
                          <div class="email">
                            <a :href="'mailto:' + teamEmpList.email">{{
                              teamEmpList.email
                            }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="i == 2">
              <div class="box" v-for="(sector, i) in emp.listSubLast" :key="i">
                <div class="group_a" :id="'subgroup_'+(i+1)">{{ sector.deptname }}</div>
                <div class="group_list">
                  <!-- 부문 -->
                  <div
                    class="list"
                    v-for="(sectorEmpList, i) in sector.empList"
                    :key="i"
                  >
                    <!-- 부문에만 소속 리스트-->
                    <div
                      class="name"
                      @mouseover="sectorEmpList.isShowImg = i"
                      @mouseleave="sectorEmpList.isShowImg = 999"
                    >
                      {{ sectorEmpList.name }}
                      <div
                        v-if="
                          sectorEmpList.isShowImg == i &&
                          sectorEmpList.photoimg != ''
                        "
                        class="img-over"
                      >
                        <img class="profile-img" :src="sectorEmpList.photoimg" />
                      </div>
                    </div>
                    <div class="phone_number">{{ sectorEmpList.phone }}</div>
                    <div class="email">
                      <a :href="'mailto:' + sectorEmpList.email">{{
                        sectorEmpList.email
                      }}</a>
                    </div>
                  </div>
                </div>
                <div v-if="sector.has_children == 'Y'">
                  <!-- 부문하위 -->
                  <div v-for="(office, i) in sector.children" :key="i">
                    <!-- 본부 or 부문-팀 -->
                    <div class="group_b" v-if="office.member_cnt > 0">
                      {{ office.deptname }}
                    </div>
                    <div class="group_list">
                      <div
                        class="list"
                        v-for="(officeEmpList, i) in office.empList"
                        :key="i"
                      >
                        <!-- 부문-본부에만 소속 리스트-->
                        <div
                          class="name"
                          @mouseover="officeEmpList.isShowImg = i"
                          @mouseleave="officeEmpList.isShowImg = 999"
                        >
                          {{ officeEmpList.name }}
                          <div
                            v-if="
                              officeEmpList.isShowImg == i &&
                              officeEmpList.photoimg != ''
                            "
                            class="img-over"
                          >
                            <img
                              class="profile-img"
                              :src="officeEmpList.photoimg"
                            />
                          </div>
                        </div>
                        <div class="phone_number">{{ officeEmpList.phone }}</div>
                        <div class="email">
                          <a :href="'mailto:' + officeEmpList.email">{{
                            officeEmpList.email
                          }}</a>
                        </div>
                      </div>
                      <div v-if="office.has_children == 'Y'">
                        <!-- 본부-팀 -->
                        <div v-for="(team, i) in office.children" :key="i">
                          <div class="team" v-if="team.has_member == 'Y'">
                            {{ team.deptname }}
                          </div>
                          <div
                            class="list"
                            v-for="(teamEmpList, i) in team.empList"
                            :key="i"
                          >
                            <!-- 팀소속 리스트-->
                            <div
                              class="name"
                              @mouseover="teamEmpList.isShowImg = i"
                              @mouseleave="teamEmpList.isShowImg = 999"
                            >
                              {{ teamEmpList.name }}
                              <div
                                v-if="
                                  teamEmpList.isShowImg == i &&
                                  teamEmpList.photoimg != ''
                                "
                                class="img-over"
                              >
                                <img
                                  class="profile-img"
                                  :src="teamEmpList.photoimg"
                                />
                              </div>
                            </div>
                            <div class="phone_number">
                              {{ teamEmpList.phone }}
                            </div>
                            <div class="email">
                              <a :href="'mailto:' + teamEmpList.email">{{
                                teamEmpList.email
                              }}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { onMounted, reactive } from "vue";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";

export default {
  components: {},
  setup() {
    const toast = useToast();
    const emp = reactive({
      uid: "",
      upw: "",
      check: "N",
      list: [],
      listSubLast: [],
      doSearch: () => {
        let params = {
          kind: "mail",
        };
        axios.post("/rest/mypims/getEmpNumber", params).then((res) => {
          if (res.data.err == 0) {
            console.log(res.data);
            emp.list = res.data.listFirst;
            emp.listSubLast = res.data.listSubLast;
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
    });

    const setViewport = () => {
      const viewport = document.getElementById("viewport");
      if (screen.width < 360) {
        viewport.setAttribute("content", "width=360, maximum-scale=1");
      } else {
        viewport.setAttribute("content", "width=1280");
      }
    };

    // const paddingTop = () => {
    //   const top = document.querySelector(".top").offsetHeight;
    //   const contact_wrapper = document.querySelector(".contact_wrapper");
    //   contact_wrapper.style.paddingTop = `${top}px`;
    // };

    onMounted(() => {
      // Mounted
      setViewport();
      // paddingTop();
      window.addEventListener("resize", setViewport);
      // window.addEventListener("resize", paddingTop);

      emp.doSearch();
    });

    return { emp };
  },
};
</script>
<style lang="scss" scoped>
.container {
  .top {
    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    padding: 10px;

    .top-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}

.profile-img {
  margin: 0;
  width: 200px;
  height: 200px;
}

.img-over {
  position: absolute;
  z-index: 555;
  min-width: 220px;
  max-width: 220px;
  overflow: hidden;
  min-height: 220px;
  min-width: 220px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 10px;
}
.contact_list_bg {
  background-color: #f3f6fb;
  padding: 26px;
  padding-top: 43px;

  .contact_list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .contact {
      flex-grow: 1;
    }

    .group_a {
      color: #ff662a;
      border: 1px solid #ff662a;
      background-color: #fff;
      padding: 7px 0;
      font-weight: 700;
      text-align: center;
      margin-bottom: 8px;
    }

    .group_b {
      font-weight: 700;
      font-size: 14px;
      color: #151515;
      margin: 10px 0 10px;
    }

    .group_list {
      border: 1px solid #eeeff3;
      border-bottom: none;

      .team {
        background-color: #f97c44;
        text-align: center;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        padding: 7px 0px;
        border-bottom: 1px solid #eeeff3;
      }

      .list {
        display: flex;
        align-items: center;
        gap: 4px;
        flex-wrap: wrap;
        font-size: 12px;
        font-weight: 400;
        color: #151515;
        padding: 7px;
        background-color: #fff;
        border-bottom: 1px solid #eeeff3;

        &.bg_col {
          background-color: #fafbff;
        }

        .name {
          width: 110px;
          word-break: break-all;
        }
        .extension_number {
          width: 47px;
          height: 20px;
          color: #5d6267;
          text-align: center;
          border-radius: 10px;
          background-color: #ecf1f7;
        }
        .phone_number {
          width: 112px;
        }
        .email {
          width: 200px;
        }
      }
    }
  }
  p {
    &:nth-of-type(1) {
      padding: 30px 0 5px 0;
    }

    font-size: 12px;
    font-weight: 400;
    color: #151515;
    padding: 0 0 5px;
    margin: 0 0 0 30px;

    span {
      font-weight: 700;
    }
  }
}
</style>
